import $ from 'jquery'

import sal from 'sal.js'

import "@iconfu/svg-inject"

import 'slick-carousel'

import './style.scss'


// DATA COLOR MANAGEMENT
var elements = document.querySelectorAll('[data-color]')
Array.prototype.forEach.call(elements, function (e) {
	if (e.dataset.color != "") {
    e.style.color = e.dataset.color
  }
})


// JQUERY DEP
$(document).ready( function() {


	// MUSIC CTRL HOME
	$("#home-music-ctrl").on("click", function(){
		$(this).toggleClass("play")
		$(this).toggleClass("playing")
		$(this).find("img").toggleClass("spinX2")
		if ($(this).hasClass("play")) {
			document.getElementById('audio-home').pause()
		} else {
			document.getElementById('audio-home').play()
		}
	})


	// VIDEO AUTOPLAY
	$(".video-wrapper").each(function(i){
		$(this).find("video").trigger("play")
		setTimeout( function(){
			$(".video-wrapper").find("video").addClass("visible")
		}, 800)
	})


  // console.log("Ready Mr. Stark")
	// Coming soon links
	$(".csoon > a").on("click", function(e){
		e.preventDefault()
		alert("A breve disponibile")
	})


	//GOTO BLOCK
	$(".goto").click(function(e) {
		e.preventDefault()
		var target= $(this).attr("href")
		if (!target) {
			target= $(this).find("a").attr("href")
		}
		if ($(this).hasClass("menu-item")) {
			$("#offcanvas-wrapper .side-menu").removeClass("visible")
			$("#offcanvas-wrapper").removeClass("visible")
			$("body").removeClass("ovfh")
		}

		$('html, body').animate({
			scrollTop: $(target).offset().top - 150
		}, 1000)
	})


  // IMG TO SVG
  SVGInject(document.querySelectorAll("img.svg"))
	SVGInject(document.querySelectorAll("img.deco"))


  // ON SCROLL HEADER ADD CLASS
  var head = $('#header')
  var top = 0
	var body = $("body")
  if ($(window).scrollTop() > top) {
    head.addClass('scrolled')
  } else {
    head.removeClass('scrolled')
  }
  $(window).scroll(function() {
    if ($(window).scrollTop() > top) {
      head.addClass('scrolled')
    } else {
      head.removeClass('scrolled')
    }
  })


  // OFFCANVAS MENU
  var tgl = $("#expamenu")
	var offcw = $("#offcanvas-wrapper")
  tgl.on("click", function(){
    $(this).find("i").toggleClass("open")
    $(this).find("i").toggleClass("close")
    $(this).find("i").each(function(i){
      if ($(this).hasClass("open")) {
				head.addClass("scrolled")
				$(this).fadeIn(100)
				// body.addClass("ovfh")
				// offcw.addClass("visible")
				// offcw.find("ul").addClass("visible")
      } else {
				$(this).hide()
				// body.removeClass("ovfh")
				// offcw.removeClass("visible")
				// offcw.find("ul").removeClass("visible")
      }
    })
		body.toggleClass("ovfh")
		offcw.toggleClass("visible")
		offcw.find("ul").toggleClass("visible")
  })


  var ap = true


  // CAROUSEL PRODUCTS
	var $elcp = $('.carousel-products')
	$elcp.on('init', function(event, slick){
    // console.log("initialized")
		SVGInject(document.querySelectorAll("img.deco"))
  })
  $elcp.slick({
    slidesToShow: 4,
    autoplay: ap,
    autoplaySpeed: 4000,
    speed: 800,
    infinite: true,
    arrows: true,
    prevArrow: $('body').find('.featuread-products .slick-left'),
    nextArrow: $('body').find('.featuread-products .slick-right'),
    responsive: [
      {
        breakpoint: 1440, // tablet breakpoint
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992, // mobile breakpoint
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
          // adaptiveHeight: true
        }
      },
      {
        breakpoint: 767, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // adaptiveHeight: true
        }
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
          // adaptiveHeight: true
        }
      }
    ]
  })
	$elcp.on('afterChange', function (event, slick, currentSlide) {
		$elcp.find("[data-slick-index='-1']").css("opacity","1")
		$elcp.find("[data-slick-index='-1']").css("visibility","visible")
	})



	// ANIMATIONS
	sal({
	  threshold: 0.05,
	  once: true
	})


})
